import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';
import Results from './Results';
import Toolbar from './Toolbar';
import { BASE_URL } from '../../../constants/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BuildingListView = ({ user }) => {
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    axios
      .get(`${BASE_URL}/building`)
      .then(response => {
        setBuildings(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('buildings', user.language)}
    >
      <Container maxWidth={false}>
        <Toolbar user={user} setSearch={setSearch} />
        <Box mt={3}>
          <Results user={user} buildings={buildings} search={search} />
        </Box>
      </Container>
    </Page>
  );
};

export default BuildingListView;
