export const getSheetData = (data, header) => {
  const fields = Object.keys(data[0]);
  const sheetData = data.map(row => {
    return fields.map(fieldName => {
      return row[fieldName] ? row[fieldName] : '';
    });
  });
  sheetData.unshift(header);
  return sheetData;
};

export const getColumnLetter = n => {
  const ordA = 'a'.charCodeAt(0);
  const ordZ = 'z'.charCodeAt(0);
  const len = ordZ - ordA + 1;

  let s = '';
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s.toUpperCase();
};
