import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  LinearProgress
} from '@material-ui/core';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const SimplifiedInspections = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const [simpleInspections, setSimpleInspections] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/company/simple-inspections`)
      .then(response => {
        setSimpleInspections(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  if (simpleInspections !== null) {
    return (
      <Formik
        initialValues={{
          simpleInspections
        }}
        onSubmit={(values, actions) => {
          axios
            .put(
              `${BASE_URL}/company/simple-inspections?simpleInspections=${values.simpleInspections}`
            )
            .then(response => {
              setSimpleInspections(response.data);
              toast.success(getLocaleString('thresholdUpdated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        }}
        enableReinitialize
      >
        {({ handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader={getLocaleString(
                  'updateInspectionMethod',
                  user.language
                )}
                title={getLocaleString('inspectionMethod', user.language)}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={6} wrap="wrap">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={getLocaleString('inspectionMethod', user.language)}
                      name="simpleInspections"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.simpleInspections}
                      variant="outlined"
                    >
                      <option value>
                        {getLocaleString('simplified', user.language)}
                      </option>
                      <option value={false}>
                        {getLocaleString('detailed', user.language)}
                      </option>
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    isSubmitting ||
                    values.simpleInspections === simpleInspections
                  }
                  type="submit"
                >
                  {getLocaleString('save', user.language)}
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    );
  }
  return <LinearProgress />;
};

SimplifiedInspections.propTypes = {
  className: PropTypes.string
};

export default SimplifiedInspections;
