import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchUsers = createAsyncThunk('/user/fetchUsers', async () => {
  try {
    const res = await axios.get(`${BASE_URL}/user`);
    return res.data;
  } catch (err) {
    toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: null
  },
  reducers: {
    addUser(state, action) {
      if (state.users) {
        state.users.push(action.payload);
      }
    },
    updateUser(state, action) {
      if (state.users) {
        state.users[state.users.findIndex(u => u.id === action.payload.id)] =
          action.payload;
      }
    },
    deleteUser(state, action) {
      if (state.users) {
        state.users = state.users.filter(u => u.id != action.payload);
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
  }
});

export const { addUser, updateUser, deleteUser } = userSlice.actions;

export default userSlice.reducer;
