import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import getLocaleString from 'src/utils/getLocaleString';
import { getRole } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersList = ({ className, user, company, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleRowClick = userId => {
    navigate(`/app/users/${userId}`);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={getLocaleString('users', user.language)} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          {!company ? (
            <LinearProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {getLocaleString('name', user.language)}
                  </TableCell>
                  <TableCell align="center">
                    {getLocaleString('email', user.language)}
                  </TableCell>
                  <TableCell align="center">
                    {getLocaleString('phone', user.language)}
                  </TableCell>
                  <TableCell align="center">
                    {getLocaleString('role', user.language)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company.users.map(compUser => (
                  <TableRow
                    hover
                    key={compUser.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(compUser.id)}
                  >
                    <TableCell align="center">{compUser.name}</TableCell>
                    <TableCell align="center">{compUser.email}</TableCell>
                    <TableCell align="center">{compUser.phone}</TableCell>
                    <TableCell align="center">
                      {getRole(compUser, user.language)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

UsersList.propTypes = {
  className: PropTypes.string
};

export default UsersList;
