import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import getLocaleString from 'src/utils/getLocaleString';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DownloadIcon from '@material-ui/icons/GetAppRounded';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    minWidth: 300
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  subsubAcc: {},
  image: {
    height: 100,
    position: 'relative',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 15
  },
  carousel: {
    marginTop: 10
  },
  carouselContainer: {
    position: 'relative',
    paddingBottom: 20
  },
  icon: {
    position: 'absolute',
    bottom: 5,
    left: '50%',
    marginLeft: -15,
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid black',
    height: 30,
    width: 30,
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}));

const responsive = {
  desktop: {
    breakpoint: { max: 2000, min: 1024 },
    items: 4,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1
  }
};

const InspectionDetail = ({
  selectedInspection,
  setSelectedInspection,
  threshold,
  user
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [expandedSub, setExpandedSub] = useState(false);
  const [expandedSubSub, setExpandedSubSub] = useState(false);
  const [isMaterialExpanded, setIsMaterialExpanded] = useState(false);

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordionSubChange = panel => (event, isExpanded) => {
    setExpandedSub(isExpanded ? panel : false);
  };

  const handleAccordionSubSubChange = panel => (event, isExpanded) => {
    setExpandedSubSub(isExpanded ? panel : false);
  };

  const getScoreTypography = (fun, param) => {
    const score = fun(param);
    let color = '';

    if (score === 100) {
      color = 'green';
    } else if (score >= threshold) {
      color = 'orange';
    } else {
      color = 'red';
    }

    if (score) {
      return (
        <Typography className={classes.secondaryHeading} style={{ color }}>
          {`${+score.toFixed(2)}%`}
        </Typography>
      );
    }
    return <Typography className={classes.secondaryHeading} />;
  };

  const getRoomScore = room => {
    if (selectedInspection.simplified) {
      const score = room.score;
      if (score) {
        return score * 10;
      } else {
        return null;
      }
    } else {
      let score = null;
      let count = 0;

      for (const element of room.elements) {
        if (element.evaluation) {
          count++;
          switch (element.evaluation) {
            case 'C':
              score += 2;
              break;
            case 'P':
              score += 1;
              break;
            case 'A':
              count--;
              break;
            default:
              break;
          }
        } else {
          score = null;
          break;
        }
      }

      if (score && count > 0) {
        score = (score * 50) / count;
      } else if (count > 0) {
        score = 0;
      }

      return score;
    }
  };

  const getFloorScore = floor => {
    let score = null;
    let count = 0;

    if (selectedInspection.simplified) {
      floor.rooms.forEach(room => {
        if (room.score) {
          score += room.score;
          count++;
        }
      });

      if (score != null && count > 0) {
        score = (score * 10) / count;
      } else if (count > 0) {
        score = 0;
      }
    } else {
      floor.rooms.forEach(room => {
        for (const element of room.elements) {
          if (element.evaluation) {
            count++;
            switch (element.evaluation) {
              case 'C':
                score += 2;
                break;
              case 'P':
                score += 1;
                break;
              case 'A':
                count--;
                break;
              default:
                break;
            }
          } else {
            break;
          }
        }
      });

      if (score != null && count > 0) {
        score = (score * 50) / count;
      } else if (count > 0) {
        score = 0;
      }
    }

    return score;
  };

  const downloadImage = async image => {
    const fileName = image.replace('https://inspectorpro.be/images/', '');
    const fetchedImage = await fetch(image);
    const imageBlog = await fetchedImage.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const parsedJSON = selectedInspection
    ? JSON.parse(selectedInspection.json)
    : null;
  return (
    <>
      {selectedInspection ? (
        <Dialog
          open={selectedInspection}
          onClose={() => setSelectedInspection(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`${selectedInspection.building.name} - ${
              selectedInspection.inspector.name
            } [${moment(selectedInspection.date).format('DD/MM/YYYY')}]`}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {selectedInspection ? (
              <>
                {parsedJSON.floors.map(floor => (
                  <Accordion
                    key={floor.id}
                    expanded={expanded === floor.id}
                    onChange={handleAccordionChange(floor.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        {floor.name}
                      </Typography>
                      {getScoreTypography(getFloorScore, floor)}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box style={{ width: '100%' }}>
                        {floor.rooms.map(room => (
                          <Accordion
                            key={room.id}
                            expanded={expandedSub === room.id}
                            onChange={handleAccordionSubChange(room.id)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2bh-content"
                              id="panel2bh-header"
                            >
                              <Typography className={classes.heading}>
                                {room.name}
                              </Typography>
                              {getScoreTypography(getRoomScore, room)}
                            </AccordionSummary>
                            <AccordionDetails>
                              {selectedInspection.simplified ? (
                                <Box>
                                  <Typography
                                    variant="h6"
                                    style={{ marginTop: 10 }}
                                  >
                                    {getLocaleString('comment', user.language)}:
                                  </Typography>
                                  {room.comment}
                                </Box>
                              ) : (
                                <Box style={{ width: '100%' }}>
                                  {room.elements.map(element => (
                                    <Accordion
                                      key={element.id}
                                      expanded={expandedSubSub === element.id}
                                      onChange={handleAccordionSubSubChange(
                                        element.id
                                      )}
                                      className={classes.subsubAcc}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                      >
                                        <Typography className={classes.heading}>
                                          {element.name}
                                        </Typography>
                                        <Typography
                                          className={classes.secondaryHeading}
                                        >
                                          {getLocaleString(
                                            element.evaluation,
                                            user.language
                                          )}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          {element.comment ||
                                            getLocaleString(
                                              'noComment',
                                              user.language
                                            )}
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                                </Box>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
                {parsedJSON.materials ? (
                  <Accordion
                    expanded={isMaterialExpanded}
                    onChange={() => setIsMaterialExpanded(!isMaterialExpanded)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className={classes.heading}>
                        {getLocaleString('equipment', user.language)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <Typography variant="h6" style={{ marginTop: 10 }}>
                          {getLocaleString('comment', user.language)}:
                        </Typography>
                        {parsedJSON.materials.comment}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <div className={classes.carouselContainer}>
              <Carousel
                className={classes.carousel}
                swipeable
                showDots
                renderDotsOutside
                responsive={responsive}
                transitionDuration={500}
              >
                {selectedInspection.images.map(image => (
                  <div>
                    <img
                      className={classes.image}
                      src={image}
                      alt="inspection"
                    />
                    <div>
                      <IconButton
                        aria-label="download"
                        className={classes.icon}
                        onClick={() => downloadImage(image)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>

            <DialogContentText id="alert-dialog-description">
              <Typography variant="h6" style={{ marginTop: 10 }}>
                {getLocaleString('comment', user.language)}:
              </Typography>
              {selectedInspection.comment}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedInspection(null)} color="primary">
              {getLocaleString('cancel', user.language)}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default InspectionDetail;
