import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const RoomList = ({ className, user, floor, setFloor, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState({});

  const handleDialogOpen = room => {
    setRoomToDelete(room);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRoomDelete = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/room/${roomToDelete.id}`)
      .then(() => {
        const newFloor = { ...floor };
        newFloor.rooms = newFloor.rooms.filter(
          room => room.id !== roomToDelete.id
        );
        setFloor(newFloor);
        toast.success(getLocaleString('roomDeleted', user.language));
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleRowClick = (event, id) => {
    if (
      !event.target.classList.contains('MuiButton-label') &&
      !event.target.classList.contains('MuiButtonBase-root')
    ) {
      navigate(`/app/rooms/${id}`, { replace: true });
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={getLocaleString('rooms', user.language)} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          {!floor ? (
            <LinearProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {getLocaleString('name', user.language)}
                  </TableCell>
                  <TableCell align="center">
                    {getLocaleString('elementCount', user.language)}
                  </TableCell>
                  {hasWriteRight(user) ? (
                    <TableCell align="center">
                      {getLocaleString('action', user.language)}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {floor.rooms.map(room => (
                  <TableRow
                    hover
                    key={room.id}
                    style={{ cursor: 'pointer' }}
                    onClick={e => handleRowClick(e, room.id)}
                  >
                    <TableCell align="center">{room.name}</TableCell>
                    <TableCell align="center">{room.elements.length}</TableCell>
                    {hasWriteRight(user) ? (
                      <TableCell align="center">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ backgroundColor: '#d11a2a' }}
                          onClick={() => handleDialogOpen(room)}
                        >
                          {getLocaleString('delete', user.language)}
                        </Button>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`${roomToDelete.name} ${getLocaleString(
                'deleteRoom',
                user.language
              )} `}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {getLocaleString('deleteRoomText', user.language)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {getLocaleString('cancel', user.language)}
              </Button>
              <Button
                onClick={handleRoomDelete}
                color="primary"
                style={{ color: '#d11a2a' }}
                autoFocus
              >
                {getLocaleString('delete', user.language)}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

RoomList.propTypes = {
  className: PropTypes.string
};

export default RoomList;
