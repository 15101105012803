import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, user, employees, search, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = employeeId => {
    navigate(`/app/employees/${employeeId}`);
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 88 }} />
                <TableCell>{getLocaleString('name', user.language)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees
                .filter(employee =>
                  employee.name.toLowerCase().includes(search.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(employee => (
                  <TableRow
                    hover
                    key={employee.id}
                    onClick={() => handleRowClick(employee.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Avatar
                        className={classes.avatar}
                        src={employee.avatarUrl}
                      >
                        {getInitials(employee.name)}
                      </Avatar>
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {employee.name}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
        count={
          employees.filter(employee =>
            employee.name.toLowerCase().includes(search.toLowerCase())
          ).length
        }
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  employees: PropTypes.array.isRequired
};

export default Results;
