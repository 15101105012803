import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles({
  root: {}
});

const Password = ({ className, user, ...rest }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string()
          .max(20, getLocaleString('passwordMax', user.language))
          .min(8, getLocaleString('passwordMin', user.language))
          .matches(/[a-z]/, getLocaleString('passowrdCapital', user.language))
          .matches(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            getLocaleString('passwordSpecial', user.language)
          )
          .matches(/\d/, getLocaleString('passwordNumber', user.language))
          .required(getLocaleString('passwordRequired', user.language)),
        password: Yup.string()
          .max(20, getLocaleString('passwordMax', user.language))
          .min(8, getLocaleString('passwordMin', user.language))
          .matches(/[a-z]/, getLocaleString('passowrdCapital', user.language))
          .matches(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            getLocaleString('passwordSpecial', user.language)
          )
          .matches(/\d/, getLocaleString('passwordNumber', user.language))
          .required(getLocaleString('passwordRequired', user.language)),
        passwordConfirmation: Yup.string()
          .oneOf(
            [Yup.ref('password'), null],
            getLocaleString('passwordMatch', user.language)
          )
          .required(getLocaleString('passwordRequired', user.language))
      })}
      onSubmit={(values, actions) => {
        axios
          .put(`${BASE_URL}/user/password`, {
            currentPassword: values.currentPassword,
            newPassword: values.password
          })
          .then(() => {
            toast.success(getLocaleString('passwordChanged', user.language));
            actions.setSubmitting(false);
          })
          .catch(err => {
            if (err.response) {
              toast.error(err.response.data.message);
            } else {
              toast.error(err.message);
            }
            actions.setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={getLocaleString('updatePassword', user.language)}
              title={getLocaleString('password', user.language)}
            />
            <Divider />

            <CardContent>
              <TextField
                fullWidth
                required
                error={Boolean(
                  touched.currentPassword && errors.currentPassword
                )}
                helperText={touched.currentPassword && errors.currentPassword}
                label={getLocaleString('currentPassword', user.language)}
                margin="normal"
                name="currentPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                value={values.currentPassword}
                variant="outlined"
              />
              <TextField
                fullWidth
                required
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                label={getLocaleString('password', user.language)}
                margin="normal"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                fullWidth
                required
                error={Boolean(
                  touched.passwordConfirmation && errors.passwordConfirmation
                )}
                helperText={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                label={getLocaleString('confirmPassword', user.language)}
                margin="normal"
                name="passwordConfirmation"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                value={values.passwordConfirmation}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="contained"
              >
                {getLocaleString('save', user.language)}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
