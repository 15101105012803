import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from 'src/constants/api';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EmployeeListView = ({ user }) => {
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    axios
      .get(`${BASE_URL}/employee`)
      .then(response => {
        setEmployees(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('employees', user.language)}
    >
      <Container maxWidth={false}>
        <Toolbar user={user} setSearch={setSearch} />
        <Box mt={3}>
          <Results user={user} employees={employees} search={search} />
        </Box>
      </Container>
    </Page>
  );
};

export default EmployeeListView;
