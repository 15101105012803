import React, { useState } from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import styled from 'styled-components';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-100 hocus:text-primary-100 text-white
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ links, className, collapseBreakpointClass = 'lg' }) => {
  const [activeLink, setActiveLink] = useState('');

  const collapseBreakPointCssMap = {
    sm: {
      mobileNavLinks: tw`sm:hidden`,
      desktopNavLinks: tw`sm:flex`,
      mobileNavLinksContainer: tw`sm:hidden`
    },
    md: {
      mobileNavLinks: tw`md:hidden`,
      desktopNavLinks: tw`md:flex`,
      mobileNavLinksContainer: tw`md:hidden`
    },
    lg: {
      mobileNavLinks: tw`lg:hidden`,
      desktopNavLinks: tw`lg:flex`,
      mobileNavLinksContainer: tw`lg:hidden`
    },
    xl: {
      mobileNavLinks: tw`lg:hidden`,
      desktopNavLinks: tw`lg:flex`,
      mobileNavLinksContainer: tw`lg:hidden`
    }
  };

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink
        href="/#"
        style={activeLink === '#' ? { color: '#8797f5' } : {}}
        onClick={() => setActiveLink('#')}
      >
        Home
      </NavLink>
      <NavLink
        href="/#service"
        className="headerNavLink"
        style={activeLink === 'service' ? { color: '#8797f5' } : {}}
        onClick={() => setActiveLink('service')}
      >
        Service
      </NavLink>
      <NavLink
        href="/#values"
        className="headerNavLink"
        style={activeLink === 'values' ? { color: '#8797f5' } : {}}
        onClick={() => setActiveLink('values')}
      >
        Values
      </NavLink>
      <NavLink
        href="/#faq"
        className="headerNavLink"
        style={activeLink === 'faq' ? { color: '#8797f5' } : {}}
        onClick={() => setActiveLink('faq')}
      >
        FAQ
      </NavLink>
      <NavLink
        href="/#contact"
        className="headerNavLink"
        style={activeLink === 'contact' ? { color: '#8797f5' } : {}}
        onClick={() => setActiveLink('contact')}
      >
        Contact
      </NavLink>
    </NavLinks>
  ];

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  links = links || defaultLinks;

  return (
    <Header
      className={className || 'header-light'}
      style={{ fontFamily: 'Arial' }}
    >
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {links}
      </DesktopNavLinks>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */
