import React from 'react';
import { Box, Container } from '@material-ui/core';
import { hasWriteRight } from 'src/utils/roleUtil';
import Toolbar from './Toolbar';
import BuildingsList from './BuildingsList';

const BuildingsListView = ({ user, customer, setCustomer }) => {
  return (
    <Container style={{ padding: 0 }}>
      <Box mt={3}>
        <BuildingsList
          user={user}
          customer={customer}
          setCustomer={setCustomer}
        />
      </Box>
      {hasWriteRight(user) ? (
        <Toolbar user={user} customer={customer} setCustomer={setCustomer} />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default BuildingsListView;
