import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { CirclePicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { addUser, updateUser, deleteUser } from 'src/redux/userSlice';
import { BASE_URL } from 'src/constants/api';
import locales from 'src/constants/locales';
import getLocaleString from 'src/utils/getLocaleString';
import { getRoles, getRoleId } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({
  user,
  currentUser,
  setUser,
  setFetchedUser,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const baseYupSchema = Yup.object().shape({
    email: Yup.string()
      .email(getLocaleString('emailValid', currentUser.language))
      .max(255)
      .required(getLocaleString('emailRequired', currentUser.language)),
    name: Yup.string()
      .max(255)
      .required(getLocaleString('nameRequired', currentUser.language)),
    phone: Yup.string().max(
      15,
      getLocaleString('phoneMax', currentUser.language)
    )
  });

  const passYupSchema = Yup.object().shape({
    email: Yup.string()
      .email(getLocaleString('emailValid', currentUser.language))
      .max(255)
      .required(getLocaleString('emailRequired', currentUser.language)),
    name: Yup.string()
      .max(255)
      .required(getLocaleString('nameRequired', currentUser.language)),
    phone: Yup.string().max(
      15,
      getLocaleString('phoneMax', currentUser.language)
    ),
    password: Yup.string()
      .max(20, getLocaleString('passwordMax', currentUser.language))
      .min(8, getLocaleString('passwordMin', currentUser.language))
      .matches(
        /[A-Z]/,
        getLocaleString('passowrdCapital', currentUser.language)
      )
      .matches(
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        getLocaleString('passwordSpecial', currentUser.language)
      )
      .matches(/\d/, getLocaleString('passwordNumber', currentUser.language))
      .required(getLocaleString('passwordRequired', currentUser.language))
  });

  const [yupSchema, setYupSchema] = useState(baseYupSchema);

  useEffect(() => {
    if (user.id === 0) {
      setYupSchema(passYupSchema);
    }

    if (currentUser.role === 'OWNER') {
      axios
        .get(`${BASE_URL}/company`)
        .then(response => {
          setCompanies(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, []);

  const handlePasswordCheckToggle = (e, handleChange) => {
    handleChange(e);
    if (e.target.checked) {
      setYupSchema(passYupSchema);
    } else {
      setYupSchema(baseYupSchema);
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteUser = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/user/${user.id}`)
      .then(() => {
        dispatch(deleteUser(user.id));
        toast.success(getLocaleString('userDeleted', currentUser.language));
        navigate('/app/users', { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        name: user.name,
        email: user.email,
        phone: user.phone,
        language: user.language,
        password: '',
        changePassword: false,
        role: getRoleId(user.role),
        companyId: user.company.id,
        color: user.color
      }}
      enableReinitialize
      validationSchema={yupSchema}
      onSubmit={(values, actions) => {
        if (user.id !== currentUser.id) {
          if (user.id === 0) {
            // Add new user
            const userObj = { ...values };
            delete userObj.changePassword;
            axios
              .post(`${BASE_URL}/user`, userObj)
              .then(response => {
                dispatch(addUser(response.data));
                navigate(`/app/users/${response.data.id}`, { replace: true });
                toast.success(
                  getLocaleString('userCreated', currentUser.language)
                );
                actions.setSubmitting(false);
              })
              .catch(err => {
                const { data } = err.response;
                if (data.status === 409) {
                  toast.error(
                    getLocaleString('emailUsed', currentUser.language)
                  );
                } else {
                  toast.error(data.message);
                }
                actions.setSubmitting(false);
              });
          } else {
            // Update other existing user
            const userObj = { ...values };
            delete userObj.changePassword;

            if (!values.changePassword) {
              delete userObj.password;
            }

            axios
              .put(`${BASE_URL}/user/update/${user.id}`, userObj)
              .then(response => {
                dispatch(updateUser(response.data));
                setFetchedUser(response.data);
                toast.success(
                  getLocaleString('userUpdated', currentUser.language)
                );
                actions.setSubmitting(false);
              })
              .catch(err => {
                const { data } = err.response;
                if (data.status === 409) {
                  toast.error(
                    getLocaleString('emailUsed', currentUser.language)
                  );
                } else {
                  toast.error(data.message);
                }
                actions.setSubmitting(false);
              });
          }
        } else {
          // Profile update
          const updateObj = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            language: values.language,
            color: values.color
          };

          if (currentUser.role === 'OWNER') {
            updateObj.companyId = values.companyId;
          }

          axios
            .put(`${BASE_URL}/user/profile`, updateObj)
            .then(response => {
              setUser(response.data);
              toast.success(
                getLocaleString('accountUpdated', currentUser.language)
              );
              actions.setSubmitting(false);
            })
            .catch(err => {
              const { data } = err.response;
              if (data.status === 409) {
                toast.error(getLocaleString('emailUsed', currentUser.language));
              } else {
                toast.error(data.message);
              }
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values,
        setFieldValue
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={
                user.id === 0
                  ? getLocaleString('newUserSub', currentUser.language)
                  : getLocaleString('editSub', currentUser.language)
              }
              title={
                user.id === 0
                  ? getLocaleString('newUser', currentUser.language)
                  : user.id === currentUser.id
                  ? getLocaleString('accountInformation', currentUser.language)
                  : getLocaleString('userInformation', currentUser.language)
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={getLocaleString('name', currentUser.language)}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label={getLocaleString(
                      'emailAddress',
                      currentUser.language
                    )}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    label={getLocaleString('phoneNumber', currentUser.language)}
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={getLocaleString(
                      'selectLanguage',
                      currentUser.language
                    )}
                    name="language"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.language}
                    variant="outlined"
                  >
                    {locales.map(locale => (
                      <option key={locale.code} value={locale.code}>
                        {locale.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {user.id !== currentUser.id ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        label={getLocaleString(
                          'password',
                          currentUser.language
                        )}
                        name="password"
                        required={values.changePassword || user.id === 0}
                        disabled={!values.changePassword && user.id !== 0}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      {user.id !== 0 ? (
                        <Box alignItems="center" display="flex" ml={-1}>
                          <Checkbox
                            checked={values.changePassword}
                            name="changePassword"
                            onChange={e =>
                              handlePasswordCheckToggle(e, handleChange)
                            }
                            onBlur={handleBlur}
                          />
                          <Typography color="textSecondary" variant="body1">
                            {getLocaleString(
                              'changePassword',
                              currentUser.language
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={getLocaleString(
                          'chooseRole',
                          currentUser.language
                        )}
                        name="role"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.role}
                        variant="outlined"
                      >
                        {getRoles(currentUser).map(role => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          margin: 'auto',
                          width: 'fit-content'
                        }}
                      >
                        <CirclePicker
                          width="102%"
                          color={values.color}
                          onChangeComplete={color =>
                            setFieldValue('color', color.hex)
                          }
                        />
                      </div>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {currentUser.role === 'OWNER' ? (
                  <Grid item md={12} xs={12}>
                    {companies ? (
                      <TextField
                        fullWidth
                        label={getLocaleString(
                          'chooseCompany',
                          currentUser.language
                        )}
                        name="companyId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.companyId}
                        variant="outlined"
                      >
                        {companies.map(company => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Grid container spacing={3}>
              <Grid item md={6} xs={6}>
                {user.id !== 0 && user.id !== currentUser.id ? (
                  <Box display="flex" justifyContent="flex-start" p={2}>
                    <Button
                      style={{ backgroundColor: '#d11a2a' }}
                      color="primary"
                      variant="contained"
                      onClick={handleDialogOpen}
                    >
                      {getLocaleString('deleteUser', currentUser.language)}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <Dialog
                  open={dialogOpen}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {getLocaleString('deleteUser', currentUser.language)}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {getLocaleString('deleteUserText', currentUser.language)}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                      {getLocaleString('cancel', currentUser.language)}
                    </Button>
                    <Button
                      onClick={handleDeleteUser}
                      color="primary"
                      style={{ color: '#d11a2a' }}
                      autoFocus
                    >
                      {getLocaleString('delete', currentUser.language)}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item md={6} xs={6}>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      (user.role === 'OWNER' && !companies)
                    }
                  >
                    {getLocaleString('save', currentUser.language)}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
