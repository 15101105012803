import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';
import Language from './Language';
import Password from './Password';
import Threshold from './Threshold';
import SimplifiedInspections from './SimplifiedInspections';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = ({ user, setUser }) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title={getLocaleString('settings', user.language)}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={hasWriteRight(user) ? 4 : 12} xs={12}>
            <Language user={user} setUser={setUser} />
          </Grid>
          {hasWriteRight(user) ? (
            <>
              <Grid item md={4} xs={12}>
                <Threshold user={user} />
              </Grid>
              <Grid item md={4} xs={12}>
                <SimplifiedInspections user={user} />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Password user={user} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SettingsView;
