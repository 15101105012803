import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, user, buildings, search, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = id => {
    navigate(`/app/buildings/${id}`, { replace: true });
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{getLocaleString('name', user.language)}</TableCell>
                <TableCell>
                  {getLocaleString('floorCount', user.language)}
                </TableCell>
                <TableCell>
                  {getLocaleString('customer', user.language)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {buildings
                .filter(building =>
                  building.name.toLowerCase().includes(search.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(building => (
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    key={building.id}
                    onClick={() => handleRowClick(building.id)}
                  >
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {building.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{building.floors.length}</TableCell>
                    <TableCell>{building.customer.name}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={
          buildings.filter(building =>
            building.name.toLowerCase().includes(search.toLowerCase())
          ).length
        }
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  buildings: PropTypes.array.isRequired
};

export default Results;
