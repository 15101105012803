import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBuildingTypes } from 'src/redux/buildingSlice';
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from 'src/constants/api';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BuildingTypeListView = ({ user }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const buildingTypes = useSelector(state => state.building.buildingTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (buildingTypes === null) {
      dispatch(fetchBuildingTypes());
    }
  }, []);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('buildingTypes', user.language)}
    >
      <Container maxWidth={false}>
        {buildingTypes === null ? (
          <Loader small />
        ) : (
          <>
            <Toolbar user={user} setSearch={setSearch} />
            <Box mt={3}>
              <Results
                user={user}
                buildingTypes={buildingTypes}
                search={search}
              />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default BuildingTypeListView;
