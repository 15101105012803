import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Divider,
  Breadcrumbs,
  Link,
  Typography
} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  Home as HomeIcon,
  AttachMoney as PermIdentityIcon,
  HorizontalSplit as HorizontalSplitIcon,
  MeetingRoom as MeetingRoomIcon
} from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import RoomDetails from './RoomDetails';
import ElementListView from './ElementListView';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}));

const RoomView = ({ user }) => {
  const classes = useStyles();
  const { roomId } = useParams();
  const [room, setRoom] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/room/${roomId}`)
      .then(response => {
        setRoom(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, [roomId]);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('room', user.language)}
    >
      {!room ? (
        <></>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  color="inherit"
                  href={`/app/customers/${room.floor.building.customer.id}`}
                  className={classes.link}
                >
                  <PermIdentityIcon className={classes.icon} />
                  {room.floor.building.customer.name}
                </Link>
                <Link
                  color="inherit"
                  href={`/app/buildings/${room.floor.building.id}`}
                  className={classes.link}
                >
                  <HomeIcon className={classes.icon} />
                  {room.floor.building.name}
                </Link>
                <Link
                  color="inherit"
                  href={`/app/floors/${room.floor.id}`}
                  className={classes.link}
                >
                  <HorizontalSplitIcon className={classes.icon} />
                  {room.floor.name}
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                  <MeetingRoomIcon className={classes.icon} />
                  {room.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <RoomDetails user={user} room={room} setRoom={setRoom} />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Divider />
              <ElementListView user={user} room={room} setRoom={setRoom} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default RoomView;
