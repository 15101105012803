import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Dialog,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMaterials,
  addMaterial,
  updateMaterial,
  deleteMaterial
} from 'src/redux/materialSlice';
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import getLocaleString from 'src/utils/getLocaleString';
import Results from './Results';
import Toolbar from './Toolbar';
import { BASE_URL } from '../../../constants/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const MaterialListView = ({ user }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [materialName, setMaterialName] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState({ id: 0, name: '' });
  const materials = useSelector(state => state.material.materials);
  const dispatch = useDispatch();

  useEffect(() => {
    if (materials === null) {
      dispatch(fetchMaterials());
    }
  }, []);

  useEffect(() => {
    if (selectedMaterial) {
      setMaterialName(selectedMaterial.name);
    }
  }, [selectedMaterial]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedMaterial({ id: 0, name: '' });
  };

  const handleDialogSave = () => {
    const payload = { name: materialName };
    if (selectedMaterial.id === 0) {
      axios
        .post(`${BASE_URL}/material`, payload)
        .then(response => {
          dispatch(addMaterial(response.data));
          toast.success(getLocaleString('equipmentCreated', user.language));
        })
        .catch(err => {
          if (err.response && err.response.data) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => {
          handleDialogClose();
        });
    } else {
      payload.id = selectedMaterial.id;
      axios
        .put(`${BASE_URL}/material/${selectedMaterial.id}`, payload)
        .then(response => {
          dispatch(updateMaterial(response.data));
          toast.success(getLocaleString('equipmentUpdated', user.language));
        })
        .catch(err => {
          if (err.response && err.response.data) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => {
          handleDialogClose();
        });
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedMaterial({ id: 0, name: '' });
  };

  const handleDialogDelete = () => {
    axios
      .delete(`${BASE_URL}/material/${selectedMaterial.id}`)
      .then(() => {
        dispatch(deleteMaterial(selectedMaterial.id));
        toast.success(getLocaleString('equipmentDeleted', user.language));
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      })
      .finally(() => {
        handleDeleteDialogClose();
      });
  };

  return (
    <Page
      className={classes.root}
      title={getLocaleString('equipment', user.language)}
    >
      <Container maxWidth={false}>
        {materials === null ? (
          <Loader small />
        ) : (
          <>
            <Toolbar
              user={user}
              setSearch={setSearch}
              setOpenDialog={setOpenDialog}
            />
            <Box mt={3}>
              <Results
                user={user}
                materials={materials}
                search={search}
                setOpenDialog={setOpenDialog}
                setDeleteDialogOpen={setDeleteDialogOpen}
                setSelectedMaterial={setSelectedMaterial}
              />
            </Box>
            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="materialName"
                  type="text"
                  fullWidth
                  value={materialName}
                  onChange={e => setMaterialName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  {getLocaleString('cancel', user.language)}
                </Button>
                <Button
                  onClick={handleDialogSave}
                  disabled={materialName.trim() === ''}
                  color="primary"
                >
                  {selectedMaterial.id === 0
                    ? getLocaleString('save', user.language)
                    : getLocaleString('update', user.language)}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={deleteDialogOpen}
              onClose={handleDeleteDialogClose}
              aria-labelledby="alert-delete-dialog-title"
              aria-describedby="alert-delete-dialog-description"
            >
              <DialogTitle id="alert-delete-dialog-title">
                {`${getLocaleString('deleteEquipment', user.language)} ${
                  selectedMaterial.name
                }`}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-delete-dialog-description">
                  {getLocaleString('deleteEquipmentText', user.language)}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteDialogClose} color="primary">
                  {getLocaleString('cancel', user.language)}
                </Button>
                <Button
                  onClick={handleDialogDelete}
                  color="primary"
                  style={{ color: '#d11a2a' }}
                >
                  {getLocaleString('delete', user.language)}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Container>
    </Page>
  );
};

export default MaterialListView;
