import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchMaterials = createAsyncThunk(
  '/material/fetchMaterials',
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/material`);
      return res.data;
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const materialSlice = createSlice({
  name: 'material',
  initialState: {
    materials: null
  },
  reducers: {
    addMaterial(state, action) {
      if (state.materials) {
        state.materials.push(action.payload);
      }
    },
    updateMaterial(state, action) {
      if (state.materials) {
        state.materials.find(m => m.id === action.payload.id).name =
          action.payload.name;
      }
    },
    deleteMaterial(state, action) {
      if (state.materials) {
        state.materials = state.materials.filter(m => m.id !== action.payload);
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchMaterials.fulfilled, (state, action) => {
      state.materials = action.payload;
    });
  }
});

export const {
  addMaterial,
  updateMaterial,
  deleteMaterial
} = materialSlice.actions;

export default materialSlice.reducer;
