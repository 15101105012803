import React from 'react';
import logo from 'src/images/InspectorLogoWhite.png';

const Logo = props => {
  return (
    <img
      alt="Logo"
      style={{ width: 50, borderRadius: 25 }}
      src={logo}
      {...props}
    />
  );
};

export default Logo;
