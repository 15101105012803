import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchStats = createAsyncThunk(
  '/statistic/fetchStats',
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/inspection/statistics`);
      console.log(res.data);
      return res.data;
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const fetchThreshold = createAsyncThunk(
  '/statistic/fetchThreshold',
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/company/threshold`);
      return res.data;
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const statisticSlice = createSlice({
  name: 'statistic',
  initialState: {
    stats: null,
    threshold: null
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      console.log('setting da sate');
      state.stats = action.payload;
    }),
      builder.addCase(fetchThreshold.fulfilled, (state, action) => {
        state.threshold = action.payload;
      });
  }
});

export default statisticSlice.reducer;
