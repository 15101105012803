import React from 'react';
import tw from 'twin.macro';

import AnimationRevealPage from 'src/helpers/AnimationRevealPage';
import Hero from 'src/components/hero/TwoColumnWithInput';
import Features from 'src/components/features/ThreeColWithSideImage';
import MainFeature2 from 'src/components/features/TwoColWithTwoFeaturesAndButton';
import FAQ from 'src/components/faqs/SingleCol';
import GetStarted from 'src/components/cta/GetStarted';
import Footer from 'src/components/footers/FiveColumnWithBackground';
import SupportIconImage from 'src/images/support-icon.svg';
import ShieldIconImage from 'src/images/shield-icon.svg';
import CustomizeIconImage from 'src/images/customize-icon.svg';
import FastIconImage from 'src/images/fast-icon.svg';
import ReliableIconImage from 'src/images/reliable-icon.svg';
import SimpleIconImage from 'src/images/simple-icon.svg';
import getLocaleString from 'src/utils/getLocaleString';
import {
  Smile as SmileIcon,
  DollarSign as MoneyIcon,
  Award as AwardIcon
} from 'react-feather';

let userLang = navigator.language || navigator.userLanguage;
if (userLang.length > 2) {
  userLang = userLang.substring(0, 2);
}

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  require('tailwindcss/dist/base.css');
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton locale={userLang} />
      <Features
        description={getLocaleString('FeatureDescription', userLang)}
        subheading={<Subheading id="service">Service</Subheading>}
        heading={
          <>
            {getLocaleString('FeatureHeding1', userLang)}{' '}
            <HighlightedText>
              {getLocaleString('FeatureHeading2', userLang)}
            </HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: ShieldIconImage,
            title: getLocaleString('Secure', userLang),
            description: getLocaleString('SecureDescription', userLang)
          },
          {
            imageSrc: SupportIconImage,
            title: getLocaleString('Support', userLang),
            description: getLocaleString('SupportDescription', userLang)
          },
          {
            imageSrc: CustomizeIconImage,
            title: getLocaleString('Customizable', userLang),
            description: getLocaleString('CustomizableDescription', userLang)
          },
          {
            imageSrc: ReliableIconImage,
            title: getLocaleString('Reliable', userLang),
            description: getLocaleString('ReliableDescription', userLang)
          },
          {
            imageSrc: FastIconImage,
            title: getLocaleString('Fast', userLang),
            description: getLocaleString('FastDescription', userLang)
          },
          {
            imageSrc: SimpleIconImage,
            title: getLocaleString('Easy', userLang),
            description: getLocaleString('EasyDescription', userLang)
          }
        ]}
      />
      <MainFeature2
        subheading={<Subheading id="values">Values</Subheading>}
        heading={
          <>
            {getLocaleString('ValuesHeading1', userLang)}{' '}
            <HighlightedText>
              {getLocaleString('ValuesHeading2', userLang)}
            </HighlightedText>
          </>
        }
        description={getLocaleString('ValuesDescription', userLang)}
        features={[
          {
            Icon: AwardIcon,
            title: getLocaleString('EaseOfUse', userLang),
            description: getLocaleString('EaseOfUseDescription', userLang)
          },
          {
            Icon: MoneyIcon,
            title: getLocaleString('Affordability', userLang),
            description: getLocaleString('AffordabilityDescription', userLang)
          },
          {
            Icon: SmileIcon,
            title: getLocaleString('CustomerSatisfaction', userLang),
            description: getLocaleString(
              'CustomerSatisfactionDescription',
              userLang
            )
          }
        ]}
      />
      <FAQ
        subheading={<Subheading id="faq">FAQS</Subheading>}
        heading={
          <>
            {getLocaleString('FaqHeading1', userLang)}{' '}
            <HighlightedText>
              {getLocaleString('FaqHeading2', userLang)}
            </HighlightedText>
          </>
        }
        description={getLocaleString('FaqDescription', userLang)}
        faqs={[
          {
            question: getLocaleString('Faq1', userLang),
            answer: getLocaleString('Faq1Answer', userLang)
          },
          {
            question: getLocaleString('Faq2', userLang),
            answer: getLocaleString('Faq2Answer', userLang)
          },
          {
            question: getLocaleString('Faq3', userLang),
            answer: getLocaleString('Faq3Answer', userLang)
          },
          {
            question: getLocaleString('Faq4', userLang),
            answer: getLocaleString('Faq4Answer', userLang)
          },
          {
            question: getLocaleString('Faq5', userLang),
            answer: getLocaleString('Faq5Answer', userLang)
          },
          {
            question: getLocaleString('Faq6', userLang),
            answer: (
              <p>
                {getLocaleString('Faq6Answer1', userLang)}{' '}
                <a
                  href="mailto:info@inspectorpro.be"
                  style={{
                    pointerEvents: 'all'
                  }}
                >
                  info@inspectorpro.be
                </a>{' '}
                {getLocaleString('Faq6Answer2', userLang)}{' '}
                <a
                  href="tel:+32483181708"
                  style={{
                    pointerEvents: 'all'
                  }}
                >
                  +32 483 18 17 08.
                </a>
              </p>
            )
          }
        ]}
      />
      <GetStarted
        text={getLocaleString('ContactText', userLang)}
        primaryLinkText={getLocaleString('ContactUs', userLang)}
        secondaryLinkText={getLocaleString('CallUs', userLang)}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
