import React, { useState } from 'react';
import {
  Typography,
  makeStyles,
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  ListItem,
  ListItemText,
  IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FixedSizeList } from 'react-window';
import { FilePlus as PlusIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  title: {
    marginLeft: 10
  },
  item: {
    borderRadius: 5,
    fontWeight: 'bold'
  },
  selected: {
    borderRadius: 5,
    fontWeight: 'bold',
    backgroundColor: 'rgb(63,81,181)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.8)'
    }
  },
  card: {
    marginTop: 10
  }
}));

const ElementList = ({
  items,
  title,
  buttonTitle,
  selectedIndex,
  handleItemClick,
  handleAddItem,
  handleRemoveItem,
  handleRename
}) => {
  const classes = useStyles();

  const [name, setName] = useState('');

  const renderRow = props => {
    const { data, index, style } = props;
    return (
      <ListItem
        button
        style={style}
        className={selectedIndex === index ? classes.selected : classes.item}
        key={data[index].name}
        onClick={
          handleItemClick && selectedIndex !== index
            ? () => handleItemClick(index)
            : () => {}
        }
        onDoubleClick={() => handleRename(data[index].name)}
      >
        <ListItemText primary={data[index].name} />
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => handleRemoveItem(index)}
        >
          <DeleteIcon />
        </IconButton>
      </ListItem>
    );
  };

  const handleAddClick = () => {
    handleAddItem(name);
    setName('');
  };

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.root}>
        <Card>
          <CardContent>
            <FixedSizeList
              style={{ width: '100%' }}
              itemData={items}
              height={400}
              width={300}
              itemSize={46}
              itemCount={items ? items.length : 0}
            >
              {renderRow}
            </FixedSizeList>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    name="name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <PlusIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  style={{ height: '100%' }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ width: '100%' }}
                    type="button"
                    disabled={
                      name.trim() === '' ||
                      items.filter(
                        item => item.name.toLowerCase() === name.toLowerCase()
                      ).length > 0
                    }
                    onClick={handleAddClick}
                  >
                    {buttonTitle}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ElementList;
