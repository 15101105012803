import React from 'react';
import { Box, Container } from '@material-ui/core';
import { hasWriteRight } from 'src/utils/roleUtil';
import Toolbar from './Toolbar';
import ElementList from './ElementList';

const ElementListView = ({ user, room, setRoom }) => {
  return (
    <Container style={{ padding: 0 }}>
      <Box mt={3}>
        <ElementList user={user} room={room} setRoom={setRoom} />
      </Box>
      {hasWriteRight(user) ? (
        <Toolbar user={user} room={room} setRoom={setRoom} />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ElementListView;
