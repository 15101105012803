import React from 'react';
import { CirclesWithBar } from 'react-loader-spinner';

const Loader = ({ small, ...rest }) => (
  <div
    style={{
      height: '100%',
      width: '100%',
      paddingTop: window.innerHeight * 0.2
    }}
    {...rest}
  >
    <CirclesWithBar
      color="#3F51B5"
      width={small ? 200 : window.innerWidth * 0.4}
      height={small ? 200 : window.innerHeight * 0.4}
      wrapperStyle={{
        display: 'flex',
        justifyContent: 'center'
      }}
    />
  </div>
);

export default Loader;
