import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Link,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  Home as HomeIcon,
  AttachMoney as PermIdentityIcon
} from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import lodash from 'lodash';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import Inspections from 'src/components/Inspections';
import InspectionYearGraph from 'src/components/InspectionYearGraph';
import getLocaleString from 'src/utils/getLocaleString';
import ElementList from 'src/components/ElementList';
import BuildingDetails from './BuildingDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  carouselItem: {
    paddingLeft: 40,
    paddingRight: 40
  },
  carouselInspectionItem: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 24
  }
}));

const BuildingView = ({ user }) => {
  const classes = useStyles();
  const { buildingId } = useParams();
  const [building, setBuilding] = useState(null);
  const [inspections, setInspections] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const [selectedFloorIndex, setSelectedFloorIndex] = useState(null);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [renameType, setRenameType] = useState(null);
  const [rename, setRename] = useState('');

  useEffect(() => {
    if (buildingId === '0') {
      setBuilding({
        id: 0,
        name: '',
        customer: {},
        floors: []
      });
    } else {
      axios
        .get(`${BASE_URL}/building/${buildingId}`)
        .then(response => {
          setBuilding(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });

      axios
        .get(`${BASE_URL}/building/${buildingId}/inspection`)
        .then(response => {
          setInspections(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }

    axios
      .get(`${BASE_URL}/building/${buildingId}/statistics`)
      .then(response => {
        setGraphData(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, [buildingId]);

  useEffect(() => {
    if (building && building.floors.length > 0) {
      if (selectedFloorIndex === null) {
        setSelectedFloorIndex(0);
      } else if (building.floors[selectedFloorIndex].rooms.length > 0) {
        if (selectedRoomIndex === null) {
          setSelectedRoomIndex(0);
        } else if (
          building.floors[selectedFloorIndex].rooms[selectedRoomIndex].elements
            .length > 0 &&
          selectedElementIndex === null
        ) {
          setSelectedElementIndex(0);
        }
      }
    }
  }, [building, selectedFloorIndex, selectedRoomIndex, selectedElementIndex]);

  const handleFloorClick = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(null);
    setSelectedFloorIndex(index);
  };

  const handleRoomClick = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(index);
  };

  const handleElementClick = index => {
    setSelectedElementIndex(index);
  };

  const handleAddFloor = name => {
    const buildingCopy = lodash.cloneDeep(building);
    buildingCopy.floors.push({ id: 0, name, rooms: [] });
    setBuilding(buildingCopy);
  };

  const handleAddRoom = name => {
    const buildingCopy = lodash.cloneDeep(building);
    buildingCopy.floors[selectedFloorIndex].rooms.push({
      id: 0,
      name,
      elements: []
    });
    setBuilding(buildingCopy);
  };

  const handleAddElement = name => {
    const buildingCopy = lodash.cloneDeep(building);
    buildingCopy.floors[selectedFloorIndex].rooms[
      selectedRoomIndex
    ].elements.push({
      id: 0,
      name
    });
    setBuilding(buildingCopy);
  };

  const handleRemoveFloor = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(null);
    setSelectedFloorIndex(null);

    const buildingCopy = lodash.cloneDeep(building);
    buildingCopy.floors.splice(index, 1);
    setBuilding(buildingCopy);
  };

  const handleRemoveRoom = index => {
    setSelectedElementIndex(null);
    setSelectedRoomIndex(null);

    const buildingCopy = lodash.cloneDeep(building);
    buildingCopy.floors[selectedFloorIndex].rooms.splice(index, 1);
    setBuilding(buildingCopy);
  };

  const handleRemoveElement = index => {
    setSelectedElementIndex(null);

    const buildingCopy = lodash.cloneDeep(building);
    buildingCopy.floors[selectedFloorIndex].rooms[
      selectedRoomIndex
    ].elements.splice(index, 1);
    setBuilding(buildingCopy);
  };

  const handleRenameDialogOpen = name => {
    setRename(name);
    setRenameDialogOpen(true);
  };

  const handleRenameDialogOpenFloor = name => {
    setRenameType('floor');
    handleRenameDialogOpen(name);
  };

  const handleRenameDialogOpenRoom = name => {
    setRenameType('room');
    handleRenameDialogOpen(name);
  };

  const handleRenameDialogOpenElement = name => {
    setRenameType('element');
    handleRenameDialogOpen(name);
  };

  const handleRenameDialogClose = () => {
    setRenameType(null);
    setRename('');
    setRenameDialogOpen(false);
  };

  const handleRenameBuildingType = () => {
    handleRenameDialogClose();
    const buildingCopy = lodash.cloneDeep(building);

    switch (renameType) {
      case 'floor':
        buildingCopy.floors[selectedFloorIndex].name = rename;
        break;
      case 'room':
        buildingCopy.floors[selectedFloorIndex].rooms[
          selectedRoomIndex
        ].name = rename;
        break;
      case 'element':
        buildingCopy.floors[selectedFloorIndex].rooms[
          selectedRoomIndex
        ].elements[selectedElementIndex].name = rename;
        break;
      default:
        break;
    }

    setBuilding(buildingCopy);
  };

  return (
    <Page
      className={classes.root}
      title={getLocaleString('building', user.language)}
    >
      {!building ? (
        <></>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {building.id !== 0 ? (
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    color="inherit"
                    href={`/app/customers/${building.customer.id}`}
                    className={classes.link}
                  >
                    <PermIdentityIcon className={classes.icon} />
                    {building.customer.name}
                  </Link>
                  <Typography color="textPrimary" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    {building.name}
                  </Typography>
                </Breadcrumbs>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <BuildingDetails
                user={user}
                building={building}
                setBuilding={setBuilding}
              />
            </Grid>
            {building.id !== 0 ? (
              <Grid item lg={12} md={12} xs={12}>
                <Carousel
                  showArrows
                  showStatus={false}
                  swipeable={false}
                  showThumbs={false}
                  infiniteLoop
                  showIndicators={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="control-arrow control-prev"
                        style={{
                          margin: 'auto',
                          height: 300,
                          opacity: 1,
                          borderRadius: 6,
                          backgroundColor: 'rgba(52, 52, 52, 0.5)'
                        }}
                      />
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="control-arrow control-next"
                        style={{
                          margin: 'auto',
                          height: 300,
                          opacity: 1,
                          borderRadius: 6,
                          backgroundColor: 'rgba(52, 52, 52, 0.5)'
                        }}
                      />
                    )
                  }
                >
                  <div className={classes.carouselItem}>
                    <Grid container spacing={3}>
                      <Grid item md={4} sm={6} xs={12}>
                        <ElementList
                          handleItemClick={handleFloorClick}
                          title={getLocaleString('groups', user.language)}
                          buttonTitle={getLocaleString(
                            'addFloor',
                            user.language
                          )}
                          items={building.floors}
                          selectedIndex={selectedFloorIndex}
                          handleAddItem={handleAddFloor}
                          handleRemoveItem={handleRemoveFloor}
                          handleRename={handleRenameDialogOpenFloor}
                        />
                      </Grid>
                      {selectedFloorIndex !== null &&
                      building.floors[selectedFloorIndex] ? (
                        <>
                          <Grid item md={4} sm={6} xs={12}>
                            <ElementList
                              handleItemClick={handleRoomClick}
                              title={getLocaleString('rooms', user.language)}
                              buttonTitle={getLocaleString(
                                'addRoom',
                                user.language
                              )}
                              items={building.floors[selectedFloorIndex].rooms}
                              selectedIndex={selectedRoomIndex}
                              handleAddItem={handleAddRoom}
                              handleRemoveItem={handleRemoveRoom}
                              handleRename={handleRenameDialogOpenRoom}
                            />
                          </Grid>
                          {selectedRoomIndex !== null &&
                          building.floors[selectedFloorIndex].rooms[
                            selectedRoomIndex
                          ] ? (
                            <Grid item md={4} sm={6} xs={12}>
                              <ElementList
                                handleItemClick={handleElementClick}
                                title={getLocaleString(
                                  'elements',
                                  user.language
                                )}
                                buttonTitle={getLocaleString(
                                  'addElement',
                                  user.language
                                )}
                                items={
                                  building.floors[selectedFloorIndex].rooms[
                                    selectedRoomIndex
                                  ].elements
                                }
                                selectedIndex={selectedElementIndex}
                                handleAddItem={handleAddElement}
                                handleRemoveItem={handleRemoveElement}
                                handleRename={handleRenameDialogOpenElement}
                              />
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <Dialog
                        open={renameDialogOpen}
                        onClose={handleRenameDialogClose}
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="rename"
                            type="text"
                            fullWidth
                            value={rename}
                            onChange={e => setRename(e.target.value)}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleRenameDialogClose}
                            color="primary"
                          >
                            {getLocaleString('cancel', user.language)}
                          </Button>
                          <Button
                            onClick={handleRenameBuildingType}
                            disabled={rename.trim() === ''}
                            color="primary"
                          >
                            {getLocaleString('update', user.language)}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </div>

                  {inspections.length > 0 ? (
                    <div className={classes.carouselInspectionItem}>
                      <Inspections
                        inspections={inspections}
                        setInspections={setInspections}
                        user={user}
                      />
                    </div>
                  ) : (
                    <Typography variant="h4">
                      {getLocaleString('noInspections', user.language)}
                    </Typography>
                  )}
                  <div className={classes.carouselInspectionItem}>
                    <InspectionYearGraph
                      title={getLocaleString(
                        'averageInspectionScore',
                        user.language
                      )}
                      stats={graphData}
                      user={user}
                    />
                  </div>
                </Carousel>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default BuildingView;
