import React from 'react';
import { Box, Container } from '@material-ui/core';
import { hasWriteRight } from 'src/utils/roleUtil';
import Toolbar from './Toolbar';
import RoomList from './RoomList';

const RoomListView = ({ user, floor, setFloor }) => {
  return (
    <Container style={{ padding: 0 }}>
      <Box mt={3}>
        <RoomList user={user} floor={floor} setFloor={setFloor} />
      </Box>
      {hasWriteRight(user) ? (
        <Toolbar user={user} floor={floor} setFloor={setFloor} />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default RoomListView;
