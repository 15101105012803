import React, { useEffect, useCallback, useRef } from 'react';

const ContextMenuCustomComponent = ({
  clientX,
  clientY,
  items,
  close,
  contextMenuText
}) => {
  const containerRef = useRef(null);

  const renderItem = item => {
    if (item.type === 'DELETE_ROW') {
      return contextMenuText.deleteRow;
    }

    if (item.type === 'DELETE_ROWS') {
      return (
        <>
          {contextMenuText.deleteRows} <b>{item.fromRow}</b>{' '}
          {contextMenuText.until} <b>{item.toRow}</b>
        </>
      );
    }

    if (item.type === 'INSERT_ROW_BELLOW') {
      return contextMenuText.insertRowBelow;
    }

    if (item.type === 'DUPLICATE_ROW') {
      return contextMenuText.duplicateRow;
    }

    if (item.type === 'DUPLICATE_ROWS') {
      return (
        <>
          {contextMenuText.duplicateRows} <b>{item.fromRow}</b>{' '}
          {contextMenuText.until} <b>{item.toRow}</b>
        </>
      );
    }

    return item.type;
  };

  const onClickOutside = useCallback(
    event => {
      const clickInside = containerRef.current?.contains(event.target);

      if (!clickInside) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  return (
    <div
      className="dsg-context-menu"
      style={{ left: `${clientX}px`, top: `${clientY}px` }}
      ref={containerRef}
    >
      {items.map(item => (
        <div
          key={item.type}
          onClick={item.action}
          className="dsg-context-menu-item"
        >
          {renderItem(item)}
        </div>
      ))}
    </div>
  );
};

export default ContextMenuCustomComponent;
