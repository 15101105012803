import getLocaleString from './getLocaleString';

export const getRole = (user, language) => {
  switch (user.role) {
    case 'OWNER':
      return getLocaleString('owner', language);
    case 'ADMIN':
      return getLocaleString('admin', language);
    case 'INSPECTOR':
      return getLocaleString('inspector', language);
    default:
      return getLocaleString('unknown', language);
  }
};

export const getRoleId = role => {
  switch (role) {
    case 'OWNER':
      return 0;
    case 'ADMIN':
      return 1;
    case 'INSPECTOR':
      return 2;
    default:
      return -1;
  }
};

export const getRoles = user => {
  const roles = [
    { id: 1, name: getLocaleString('admin', user.language) },
    { id: 2, name: getLocaleString('inspector', user.language) }
  ];
  if (user.role === 'OWNER') {
    roles.push({ id: 0, name: getLocaleString('owner', user.language) });
  }
  return roles;
};

export const hasWriteRight = user => {
  return user.role === 'ADMIN' || user.role === 'OWNER';
};
