import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from 'src/redux/userSlice';
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import getLocaleString from 'src/utils/getLocaleString';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UserListView = ({ user }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const users = useSelector(state => state.user.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('users', user.language)}
    >
      <Container maxWidth={false}>
        {users === null ? (
          <Loader small />
        ) : (
          <>
            <Toolbar
              user={user}
              setSearch={setSearch}
              userCount={users.length}
            />
            <Box mt={3}>
              <Results user={user} users={users} search={search} />
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
};

export default UserListView;
