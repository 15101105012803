import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, makeStyles, Divider } from '@material-ui/core';

import axios from 'axios';
import { toast } from 'material-react-toastify';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import Loader from 'src/components/Loader';
import getLocaleString from 'src/utils/getLocaleString';
import CompanyProfile from './CompanyProfile';
import CompanyProfileDetails from './CompanyProfileDetails';
import UsersListView from './UsersListView';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}));

const CompanyView = ({ user }) => {
  const classes = useStyles();
  const { companyId } = useParams();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (companyId === '0') {
      setCompany({
        id: 0,
        name: '',
        email: '',
        phone: '',
        location: '',
        threshold: 95,
        users: [],
        userLimit: 3,
        disabled: false,
        simpleInspections: false
      });
    } else {
      axios
        .get(`${BASE_URL}/company/${companyId}`)
        .then(response => {
          setCompany(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, [companyId]);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('company', user.language)}
    >
      {!company ? (
        <Loader />
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {companyId !== '0' ? (
              <Grid item lg={4} md={6} xs={12}>
                <CompanyProfile user={user} company={company} />
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              item
              lg={companyId !== '0' ? 8 : 12}
              md={companyId !== '0' ? 6 : 12}
              xs={12}
            >
              <CompanyProfileDetails
                user={user}
                company={company}
                setCompany={setCompany}
              />
            </Grid>
            {companyId !== '0' ? (
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Divider />
                <UsersListView user={user} company={company} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default CompanyView;
