import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Link,
  Typography
} from '@material-ui/core';
import {
  NavigateNext as NavigateNextIcon,
  Home as HomeIcon,
  AttachMoney as PermIdentityIcon,
  HorizontalSplit as HorizontalSplitIcon,
  MeetingRoom as MeetingRoomIcon,
  Extension as ExtensionIcon
} from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'material-react-toastify';
import Page from 'src/components/Page';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import ElementDetails from './ElementDetails';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  link: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}));

const ElementView = ({ user }) => {
  const classes = useStyles();
  const { elementId } = useParams();
  const [element, setElement] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/element/${elementId}`)
      .then(response => {
        setElement(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, [elementId]);

  return (
    <Page
      className={classes.root}
      title={getLocaleString('element', user.language)}
    >
      {!element ? (
        <></>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  color="inherit"
                  href={`/app/customers/${element.room.floor.building.customer.id}`}
                  className={classes.link}
                >
                  <PermIdentityIcon className={classes.icon} />
                  {element.room.floor.building.customer.name}
                </Link>
                <Link
                  color="inherit"
                  href={`/app/buildings/${element.room.floor.building.id}`}
                  className={classes.link}
                >
                  <HomeIcon className={classes.icon} />
                  {element.room.floor.building.name}
                </Link>
                <Link
                  color="inherit"
                  href={`/app/floors/${element.room.floor.id}`}
                  className={classes.link}
                >
                  <HorizontalSplitIcon className={classes.icon} />
                  {element.room.floor.name}
                </Link>
                <Link
                  color="inherit"
                  href={`/app/rooms/${element.room.id}`}
                  className={classes.link}
                >
                  <MeetingRoomIcon className={classes.icon} />
                  {element.room.name}
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                  <ExtensionIcon className={classes.icon} />
                  {element.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ElementDetails
                user={user}
                element={element}
                setElement={setElement}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default ElementView;
