import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import moment from 'moment';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import getLocaleString from 'src/utils/getLocaleString';
import { BASE_URL } from 'src/constants/api';
import { hasWriteRight } from 'src/utils/roleUtil';
import InspectionDetail from 'src/components/InspectionDetail';

const Inspections = ({ inspections, setInspections, user, ...rest }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inspectionToDelete, setInspectionToDelete] = useState({});
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [threshold, setThreshold] = useState(95);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteDialogOpen = inspection => {
    setInspectionToDelete(inspection);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    handleDeleteDialogClose();
    axios
      .delete(`${BASE_URL}/inspection/${inspectionToDelete.id}`)
      .then(() => {
        let newInspections = { ...inspections };
        newInspections = inspections.filter(
          inspection => inspection.id !== inspectionToDelete.id
        );
        setInspections(newInspections);
        toast.success(getLocaleString('inspectionDeleted', user.language));
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/company/threshold`)
      .then(response => {
        setThreshold(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={hasWriteRight(user) ? 7 : 6} align="center">
                  <Toolbar style={{ width: '100%' }}>
                    <Typography
                      style={{ flex: '0 0 100%' }}
                      variant="h4"
                      id="tableTitle"
                      component="div"
                    >
                      {getLocaleString('inspections', user.language)}
                    </Typography>
                  </Toolbar>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {getLocaleString('date', user.language)}
                </TableCell>
                <TableCell align="center">
                  {getLocaleString('startTime', user.language)}
                </TableCell>
                <TableCell align="center">
                  {getLocaleString('endTime', user.language)}
                </TableCell>
                <TableCell align="center">
                  {getLocaleString('inspector', user.language)}
                </TableCell>
                <TableCell align="center">
                  {getLocaleString('score', user.language)}
                </TableCell>
                <TableCell align="center">
                  {getLocaleString('building', user.language)}
                </TableCell>
                {hasWriteRight(user) ? (
                  <TableCell align="center">
                    {getLocaleString('action', user.language)}
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {inspections
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(inspection => (
                  <TableRow
                    hover
                    key={inspection.id}
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      if (
                        !e.target.classList.contains('MuiButton-label') &&
                        !e.target.classList.contains('MuiButtonBase-root')
                      ) {
                        setSelectedInspection(inspection);
                      }
                    }}
                  >
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {moment(inspection.date).format('DD/MM/YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {moment(inspection.startTime).format('HH:mm')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {moment(inspection.date).format('HH:mm')}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {inspection.inspector.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {inspection.score && +inspection.score.toFixed(2)}%
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="textPrimary" variant="body1">
                        {inspection.building.name}
                      </Typography>
                    </TableCell>
                    {hasWriteRight(user) ? (
                      <TableCell align="center">
                        <Button
                          onClick={() => handleDeleteDialogOpen(inspection)}
                          color="primary"
                          style={{ color: 'white', backgroundColor: '#d11a2a' }}
                        >
                          {getLocaleString('delete', user.language)}
                        </Button>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
        count={inspections.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="alert-delete-dialog-title"
        aria-describedby="alert-delete-dialog-description"
      >
        <DialogTitle id="alert-delete-dialog-title">
          {`${getLocaleString('deleteInspection', user.language)} ${moment(
            inspectionToDelete.date
          ).format('DD/MM/YYYY')}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            {getLocaleString('deleteInspectionText', user.language)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            {getLocaleString('cancel', user.language)}
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            style={{ color: '#d11a2a' }}
          >
            {getLocaleString('delete', user.language)}
          </Button>
        </DialogActions>
      </Dialog>
      <InspectionDetail
        selectedInspection={selectedInspection}
        setSelectedInspection={setSelectedInspection}
        threshold={threshold}
        user={user}
      />
    </Card>
  );
};

export default Inspections;
