import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import getInitials from 'src/utils/getInitials';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, user, customers, search, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = customerId => {
    navigate(`/app/customers/${customerId}`);
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 88 }} />
                <TableCell>ID</TableCell>
                <TableCell>{getLocaleString('name', user.language)}</TableCell>
                <TableCell>{getLocaleString('phone', user.language)}</TableCell>
                <TableCell>
                  {getLocaleString('section', user.language)}
                </TableCell>
                <TableCell>
                  {getLocaleString('inspectionDone', user.language)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers
                .filter(customer =>
                  customer.name.toLowerCase().includes(search.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(customer => {
                  let stringId = customer.id.toString();

                  while (stringId.length < 4) {
                    stringId = '0' + stringId;
                  }

                  return (
                    <TableRow
                      hover
                      key={customer.id}
                      onClick={() => handleRowClick(customer.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <Avatar
                          className={classes.avatar}
                          src={customer.avatarUrl}
                        >
                          {getInitials(customer.name)}
                        </Avatar>
                      </TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Typography color="textPrimary" variant="body1">
                            {stringId}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Typography color="textPrimary" variant="body1">
                            {customer.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{customer.phone}</TableCell>
                      <TableCell>
                        {customer.customerGroup && customer.customerGroup.name}
                      </TableCell>
                      <TableCell>
                        {customer.withinFrequency ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        labelRowsPerPage={getLocaleString('rowsPerPage', user.language)}
        count={
          customers.filter(customer =>
            customer.name.toLowerCase().includes(search.toLowerCase())
          ).length
        }
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
