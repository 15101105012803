import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'src/constants/api';
import { toast } from 'material-react-toastify';

export const fetchBuildingTypes = createAsyncThunk(
  '/building/fetchBuildingTypes',
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}/buildingtype`);
      return res.data;
    } catch (err) {
      toast.error("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  }
);

export const buildingSlice = createSlice({
  name: 'building',
  initialState: {
    buildingTypes: null
  },
  reducers: {
    addBuildingType(state, action) {
      if (state.buildingTypes) {
        state.buildingTypes.push(action.payload);
      }
    },
    updateBuildingType(state, action) {
      if (state.buildingTypes) {
        state.buildingTypes[
          state.buildingTypes.findIndex(bt => bt.id === action.payload.id)
        ] = action.payload;
      }
    },
    deleteBuildingType(state, action) {
      if (state.buildingTypes) {
        state.buildingTypes = state.buildingTypes.filter(
          bt => bt.id !== action.payload
        );
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchBuildingTypes.fulfilled, (state, action) => {
      state.buildingTypes = action.payload;
    });
  }
});

export const {
  addBuildingType,
  updateBuildingType,
  deleteBuildingType
} = buildingSlice.actions;

export default buildingSlice.reducer;
