import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ElementList = ({ className, user, room, setRoom, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [elementToDelete, setElementToDelete] = useState({});

  const handleDialogOpen = element => {
    setElementToDelete(element);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRoomDelete = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/element/${elementToDelete.id}`)
      .then(() => {
        const newRoom = { ...room };
        newRoom.elements = newRoom.elements.filter(
          element => element.id !== elementToDelete.id
        );
        setRoom(newRoom);
        toast.success(getLocaleString('elementDeleted', user.language));
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  const handleRowClick = (event, id) => {
    if (
      !event.target.classList.contains('MuiButton-label') &&
      !event.target.classList.contains('MuiButtonBase-root')
    ) {
      navigate(`/app/elements/${id}`, { replace: true });
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={getLocaleString('elements', user.language)} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          {!room ? (
            <LinearProgress />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {getLocaleString('name', user.language)}
                  </TableCell>
                  {hasWriteRight(user) ? (
                    <TableCell align="center">
                      {getLocaleString('action', user.language)}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {room.elements.map(element => (
                  <TableRow
                    hover
                    key={element.id}
                    style={{ cursor: 'pointer' }}
                    onClick={e => handleRowClick(e, element.id)}
                  >
                    <TableCell align="center">{element.name}</TableCell>
                    {hasWriteRight(user) ? (
                      <TableCell align="center">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ backgroundColor: '#d11a2a' }}
                          onClick={() => handleDialogOpen(element)}
                        >
                          {getLocaleString('delete', user.language)}
                        </Button>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`${elementToDelete.name} ${getLocaleString(
                'deleteElement',
                user.language
              )} `}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {getLocaleString('deleteElementText', user.language)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {getLocaleString('cancel', user.language)}
              </Button>
              <Button
                onClick={handleRoomDelete}
                color="primary"
                style={{ color: '#d11a2a' }}
                autoFocus
              >
                {getLocaleString('delete', user.language)}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

ElementList.propTypes = {
  className: PropTypes.string
};

export default ElementList;
