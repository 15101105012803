import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import {
  addCustomer,
  updateCustomer,
  deleteCustomer
} from 'src/redux/customerSlice';
import { fetchCustomerGroups } from 'src/redux/customerGroupSlice';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';
import locales from 'src/constants/locales';

const useStyles = makeStyles(() => ({
  root: {}
}));

const CustomerProfileDetails = ({
  user,
  customer,
  setCustomer,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const customerGroups = useSelector(
    state => state.customerGroup.customerGroups
  );
  const dispatch = useDispatch();
  const canEdit = hasWriteRight(user);

  useEffect(() => {
    if (customerGroups === null) {
      dispatch(fetchCustomerGroups());
    }

    if (user.role === 'OWNER') {
      axios
        .get(`${BASE_URL}/company`)
        .then(response => {
          setCompanies(response.data);
        })
        .catch(err => {
          if (err.response) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteCustomer = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/customer/${customer.id}`)
      .then(() => {
        dispatch(deleteCustomer(customer.id));
        toast.success(getLocaleString('customerDeleted', user.language));
        navigate('/app/customers', { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        name: customer.name,
        contacts: customer.contacts,
        phone: customer.phone,
        language: customer.language,
        companyId: customer.company.id,
        customerGroupId: customer.customerGroup ? customer.customerGroup.id : 0,
        frequencyType: customer.frequencyType,
        frequency: customer.frequency,
        description: customer.description
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(255)
          .required(getLocaleString('nameRequired', user.language)),
        phone: Yup.string().max(15, getLocaleString('phoneMax', user.language)),
        frequency: Yup.number()
          .min(1, 'Cannot be less than 1')
          .max(31, 'Cannot be more than 31')
          .required('is required')
      })}
      onSubmit={(values, actions) => {
        if (customer.id === 0) {
          // Add new customer
          axios
            .post(`${BASE_URL}/customer`, values)
            .then(response => {
              dispatch(addCustomer(response.data));
              navigate(`/app/customers/${response.data.id}`, { replace: true });
              toast.success(getLocaleString('customerCreated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        } else {
          // Update customer
          axios
            .put(`${BASE_URL}/customer/${customer.id}`, values)
            .then(response => {
              dispatch(updateCustomer(response.data));
              setCustomer(response.data);
              toast.success(getLocaleString('customerUpdated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={
                customer.id === 0 ? (
                  getLocaleString('newSub', user.language)
                ) : (
                  <>
                    {canEdit ? getLocaleString('editSub', user.language) : ''}
                  </>
                )
              }
              title={
                customer.id === 0
                  ? getLocaleString('newCustomer', user.language)
                  : getLocaleString('customerInfo', user.language)
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={getLocaleString('name', user.language)}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    InputProps={!canEdit ? { readOnly: true } : {}}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    error={Boolean(touched.contacts && errors.contacts)}
                    helperText={touched.contacts && errors.contacts}
                    label={getLocaleString('contacts', user.language)}
                    name="contacts"
                    onChange={(e, newValue) =>
                      setFieldValue('contacts', newValue)
                    }
                    onBlur={handleBlur}
                    required
                    InputProps={!canEdit ? { readOnly: true } : {}}
                    value={values.contacts}
                    variant="outlined"
                    multiple
                    options={values.contacts}
                    freeSolo
                    disableClearable
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        value=""
                        variant="outlined"
                        label={getLocaleString('contacts', user.language)}
                        placeholder={getLocaleString('add', user.language)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    label={getLocaleString('phoneNumber', user.language)}
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={!canEdit ? { readOnly: true } : {}}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={getLocaleString('language', user.language)}
                    name="language"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    disabled={!canEdit}
                    value={values.language}
                    variant="outlined"
                  >
                    {locales.map(locale => (
                      <option key={locale.code} value={locale.code}>
                        {locale.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={5}>
                  <TextField
                    fullWidth
                    required
                    error={Boolean(touched.frequency && errors.frequency)}
                    helperText={touched.frequency && errors.frequency}
                    label={getLocaleString('frequency', user.language)}
                    name="frequency"
                    onChange={e => {
                      if (e.target.value < 1) {
                        e.target.value = 1;
                      } else if (e.target.value > 31) {
                        e.target.value = 31;
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    InputProps={
                      !canEdit
                        ? { readOnly: true, min: 1, max: 31 }
                        : { min: 1, max: 31 }
                    }
                    value={values.frequency}
                    variant="outlined"
                    type="number"
                  />
                </Grid>
                <Grid item md={4} xs={7}>
                  <TextField
                    fullWidth
                    label={getLocaleString('period', user.language)}
                    name="frequencyType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    disabled={!canEdit}
                    value={values.frequencyType}
                    variant="outlined"
                  >
                    <option value={5}>
                      {getLocaleString('days', user.language)}
                    </option>
                    <option value={3}>
                      {getLocaleString('weeks', user.language)}
                    </option>
                    <option value={2}>
                      {getLocaleString('months', user.language)}
                    </option>
                    <option value={1}>
                      {getLocaleString('years', user.language)}
                    </option>
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  {customerGroups ? (
                    <TextField
                      fullWidth
                      label={getLocaleString('selectSection', user.language)}
                      name="customerGroupId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      select
                      SelectProps={{ native: true }}
                      value={values.customerGroupId}
                      disabled={!canEdit}
                      variant="outlined"
                    >
                      <option value={0}>
                        {getLocaleString(
                          'selectSectionPlaceholder',
                          user.language
                        )}
                      </option>
                      {customerGroups.map(customerGroup => (
                        <option key={customerGroup.id} value={customerGroup.id}>
                          {customerGroup.name}
                        </option>
                      ))}
                    </TextField>
                  ) : (
                    <CircularProgress />
                  )}
                </Grid>
                {user.role === 'OWNER' ? (
                  <Grid item md={12} xs={12}>
                    {companies ? (
                      <TextField
                        fullWidth
                        label={getLocaleString('chooseCompany', user.language)}
                        name="companyId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.companyId}
                        variant="outlined"
                      >
                        {companies.map(company => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={getLocaleString('description', user.language)}
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 255 }}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            {canEdit ? (
              <>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    {customer.id !== 0 ? (
                      <Box display="flex" justifyContent="flex-start" p={2}>
                        <Button
                          style={{ backgroundColor: '#d11a2a' }}
                          color="primary"
                          variant="contained"
                          onClick={handleDialogOpen}
                        >
                          {getLocaleString('deleteCustomer', user.language)}
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Dialog
                      open={dialogOpen}
                      onClose={handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {getLocaleString('deleteCustomer', user.language)}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {getLocaleString('deleteCustomerText', user.language)}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                          {getLocaleString('cancel', user.language)}
                        </Button>
                        <Button
                          onClick={handleDeleteCustomer}
                          color="primary"
                          style={{ color: '#d11a2a' }}
                          autoFocus
                        >
                          {getLocaleString('delete', user.language)}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          (user.role === 'OWNER' && !companies)
                        }
                      >
                        {getLocaleString('save', user.language)}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

CustomerProfileDetails.propTypes = {
  className: PropTypes.string
};

export default CustomerProfileDetails;
