import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import axios from 'axios';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import Loader from './components/Loader';
import { BASE_URL } from './constants/api';

axios.defaults.withCredentials = true;

const App = () => {
  const [user, setUser] = useState(null);
  const [loginCheckDone, setLoginCheckDone] = useState(false);
  const routing = useRoutes(routes(user, setUser));

  const isLoggedIn = async () => {
    return axios.get(`${BASE_URL}/isauthenticated`);
  };

  useEffect(() => {
    isLoggedIn().then(loggedIn => {
      if (loggedIn.data) {
        axios.get(`${BASE_URL}/user/me`).then(response => {
          setUser(response.data);
          setLoginCheckDone(true);
        });
      } else {
        setLoginCheckDone(true);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {loginCheckDone ? <>{routing}</> : <Loader />}
    </ThemeProvider>
  );
};

export default App;
