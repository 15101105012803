import React from 'react';

const MonthBox = ({ value, onClick }) => {
  const _handleClick = e => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div className="box" onClick={_handleClick}>
      <label>{value}</label>
    </div>
  );
};

export default MonthBox;
