import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Grid,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import lodash from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from 'src/redux/userSlice';
import {
  fetchCustomerGroups,
  addCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup
} from 'src/redux/customerGroupSlice';
import { BASE_URL } from 'src/constants/api';
import Page from 'src/components/Page';
import getLocaleString from 'src/utils/getLocaleString';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = ({ user }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { customerGroupId } = useParams();
  const [customerGroup, setCustomerGroup] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const users = useSelector(state => state.user.users);
  const customerGroups = useSelector(
    state => state.customerGroup.customerGroups
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (users === null) {
      dispatch(fetchUsers());
    }
  }, []);

  useEffect(() => {
    if (customerGroupId === '0') {
      setCustomerGroup({
        id: 0,
        name: '',
        inspector: null
      });
    } else {
      if (customerGroups === null) {
        dispatch(fetchCustomerGroups());
      } else {
        setCustomerGroup(customerGroups.find(cg => cg.id == customerGroupId));
      }
    }
  }, [customerGroupId, customerGroups]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteCustomerGroup = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/customer-group/${customerGroup.id}`)
      .then(() => {
        dispatch(deleteCustomerGroup(customerGroup.id));
        toast.success(getLocaleString('sectionDeleted', user.language));
        navigate('/app/customergroups', { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Page
      className={classes.root}
      title={getLocaleString('section', user.language)}
    >
      <Container maxWidth="lg">
        {!customerGroup || !users ? (
          <></>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  name: customerGroup.name,
                  inspectorId: customerGroup.inspector
                    ? customerGroup.inspector.id
                    : 0
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(255)
                    .required(getLocaleString('nameRequired', user.language))
                })}
                onSubmit={(values, actions) => {
                  const customerGroupCopy = lodash.cloneDeep(customerGroup);
                  customerGroupCopy.name = values.name;
                  customerGroupCopy.companyId = user.company.id;
                  customerGroupCopy.inspectorId = values.inspectorId;

                  if (customerGroup.id === 0) {
                    axios
                      .post(`${BASE_URL}/customer-group`, customerGroupCopy)
                      .then(response => {
                        dispatch(addCustomerGroup(response.data));
                        navigate(`/app/customergroups/${response.data.id}`, {
                          replace: true
                        });
                        toast.success(
                          getLocaleString('sectionCreated', user.language)
                        );
                        actions.setSubmitting(false);
                      })
                      .catch(err => {
                        if (err.response) {
                          toast.error(err.response.data.message);
                        } else {
                          toast.error(err.message);
                        }
                        actions.setSubmitting(false);
                      });
                  } else {
                    axios
                      .put(
                        `${BASE_URL}/customer-group/${customerGroup.id}`,
                        customerGroupCopy
                      )
                      .then(response => {
                        dispatch(updateCustomerGroup(response.data));
                        setCustomerGroup(response.data);
                        toast.success(
                          getLocaleString('sectionUpdated', user.language)
                        );
                        actions.setSubmitting(false);
                      })
                      .catch(err => {
                        if (err.response) {
                          toast.error(err.response.data.message);
                        } else {
                          toast.error(err.message);
                        }
                        actions.setSubmitting(false);
                      });
                  }
                }}
              >
                {({
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  values,
                  errors,
                  touched
                }) => (
                  <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Card>
                      <CardHeader
                        subheader={
                          customerGroup.id === 0
                            ? getLocaleString('newSub', user.language)
                            : getLocaleString('editSub', user.language)
                        }
                        title={
                          customerGroup.id === 0
                            ? getLocaleString('newSection', user.language)
                            : getLocaleString('updateSection', user.language)
                        }
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              label={getLocaleString('name', user.language)}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              value={values.name}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label={getLocaleString(
                                'selectInspector',
                                user.language
                              )}
                              name="inspectorId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              select
                              SelectProps={{ native: true }}
                              value={values.inspectorId}
                              variant="outlined"
                            >
                              <option value={0}>
                                {getLocaleString(
                                  'selectInspectorPlaceholder',
                                  user.language
                                )}
                              </option>
                              {users.map(listUser => (
                                <option key={listUser.id} value={listUser.id}>
                                  {listUser.name}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={6}>
                          {customerGroup.id !== 0 ? (
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              p={2}
                            >
                              <Button
                                style={{ backgroundColor: '#d11a2a' }}
                                color="primary"
                                variant="contained"
                                onClick={handleDialogOpen}
                              >
                                {getLocaleString(
                                  'deleteSection',
                                  user.language
                                )}
                              </Button>
                            </Box>
                          ) : (
                            <></>
                          )}
                          <Dialog
                            open={dialogOpen}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {getLocaleString('deleteSection', user.language)}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {getLocaleString(
                                  'deleteSectionText',
                                  user.language
                                )}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleDialogClose}
                                color="primary"
                              >
                                {getLocaleString('cancel', user.language)}
                              </Button>
                              <Button
                                onClick={handleDeleteCustomerGroup}
                                color="primary"
                                style={{ color: '#d11a2a' }}
                                autoFocus
                              >
                                {getLocaleString('delete', user.language)}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              disabled={isSubmitting || !isValid}
                            >
                              {getLocaleString('save', user.language)}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Card>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default SettingsView;
