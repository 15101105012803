import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BASE_URL } from 'src/constants/api';
import getLocaleString from 'src/utils/getLocaleString';
import { hasWriteRight } from 'src/utils/roleUtil';

const useStyles = makeStyles(() => ({
  root: {}
}));

const BuildingDetails = ({
  user,
  building,
  setBuilding,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/customer`)
      .then(response => {
        setCustomers(response.data);
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteBuilding = () => {
    handleDialogClose();
    axios
      .delete(`${BASE_URL}/building/${building.id}`)
      .then(() => {
        toast.success(getLocaleString('buildingDeleted', user.language));
        navigate(`/app/customers/${building.customer.id}`, { replace: true });
      })
      .catch(err => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        name: building.name,
        address: building.address,
        contacts: building.contacts,
        customerId: building.customer.id
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        address: Yup.string()
          .max(255)
          .required(getLocaleString('addressRequired', user.language)),
        customerId: Yup.number()
          .typeError(getLocaleString('customerRequired', user.language))
          .required(getLocaleString('customerRequired', user.language))
      })}
      onSubmit={(values, actions) => {
        if (building.id === 0) {
          // Add new building
          axios
            .post(`${BASE_URL}/building`, values)
            .then(response => {
              navigate(`/app/buildings/${response.data.id}`, { replace: true });
              toast.success(getLocaleString('buildingCreated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        } else {
          // Update building
          axios
            .put(`${BASE_URL}/building/${building.id}`, values)
            .then(response => {
              setBuilding(response.data);
              toast.success(getLocaleString('buildingUpdated', user.language));
              actions.setSubmitting(false);
            })
            .catch(err => {
              if (err.response) {
                toast.error(err.response.data.message);
              } else {
                toast.error(err.message);
              }
              actions.setSubmitting(false);
            });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        isValid,
        values
      }) => (
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader={
                building.id === 0 ? (
                  getLocaleString('newSub', user.language)
                ) : (
                  <>
                    {hasWriteRight(user)
                      ? getLocaleString('editSub', user.language)
                      : ''}
                  </>
                )
              }
              title={
                building.id === 0
                  ? getLocaleString('newBuilding', user.language)
                  : getLocaleString('buildingInfo', user.language)
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    label={getLocaleString('name', user.language)}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={!hasWriteRight(user) ? { readOnly: true } : {}}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth
                    error={Boolean(touched.contacts && errors.contacts)}
                    helperText={touched.contacts && errors.contacts}
                    label={getLocaleString('contacts', user.language)}
                    name="contacts"
                    onChange={(e, newValue) =>
                      setFieldValue('contacts', newValue)
                    }
                    onBlur={handleBlur}
                    required
                    value={values.contacts}
                    variant="outlined"
                    multiple
                    options={values.contacts}
                    freeSolo
                    disableClearable
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        value=""
                        variant="outlined"
                        label={getLocaleString('contacts', user.language)}
                        placeholder={getLocaleString('add', user.language)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    label={getLocaleString('address', user.language)}
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    InputProps={!hasWriteRight(user) ? { readOnly: true } : {}}
                    value={values.address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {customers ? (
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label={getLocaleString('customer', user.language)}
                      name="customerId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      disabled={!hasWriteRight(user)}
                      select
                      SelectProps={{ native: true }}
                      value={values.customerId}
                      variant="outlined"
                    >
                      <option value={null}>
                        {getLocaleString(
                          'ChooseCustomerPlaceholder',
                          user.language
                        )}
                      </option>
                      {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </TextField>
                  ) : (
                    <CircularProgress />
                  )}
                </Grid>
              </Grid>
            </CardContent>
            {hasWriteRight(user) ? (
              <>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    {building.id !== 0 ? (
                      <Box display="flex" justifyContent="flex-start" p={2}>
                        <Button
                          style={{ backgroundColor: '#d11a2a' }}
                          color="primary"
                          variant="contained"
                          onClick={handleDialogOpen}
                        >
                          {getLocaleString('deleteBuilding', user.language)}
                        </Button>
                        <Dialog
                          open={dialogOpen}
                          onClose={handleDialogClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {getLocaleString('deleteBuilding', user.language)}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              {getLocaleString(
                                'deleteBuildingText',
                                user.language
                              )}
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                              {getLocaleString('cancel', user.language)}
                            </Button>
                            <Button
                              onClick={handleDeleteBuilding}
                              color="primary"
                              style={{ color: '#d11a2a' }}
                              autoFocus
                            >
                              {getLocaleString('delete', user.language)}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                          building.id === 0
                            ? isValid || isSubmitting || !customers
                            : isSubmitting
                        }
                      >
                        {getLocaleString('save', user.language)}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Divider />
          </Card>
        </form>
      )}
    </Formik>
  );
};

BuildingDetails.propTypes = {
  className: PropTypes.string
};

export default BuildingDetails;
